import { TBool } from '../utils'

class Progress {
  constructor() {
    this.progressListeners = []
    console.log('create progress')
  }
  observe(handler) {
    this.progressListeners.push(handler)
  }
  notify(value) {
    this.progressListeners.forEach((cb) => {
      cb(value)
    })
  }
}
export default class ResponsePromise {
  constructor(executor) {
    const canceled = new TBool()
    const progress = new Progress()
    this.canceled = canceled
    this.pro = progress
    this._resolveObservers = []
    this._rejectObserver = []
    this._catchObserver = []
    this._finallyObserver = []

    setTimeout(() => {
      executor(
        this._resolve.bind(this),
        this._reject.bind(this),
        progress,
        canceled
      )
    }, 1)
  }

  _resolve(data) {
    this._resolveObservers.forEach((cb) => {
      cb(data)
    })
  }

  _reject(data) {
    this._rejectObserver.forEach((cb) => {
      cb(data)
    })
  }

  then(res, rej) {
    if (res != undefined) {
      this._resolveObservers.push(res)
    }

    if (rej != undefined) {
      this._rejectObserver.push(rej)
    }

    return this
  }

  progress(handler) {
    this.pro.observe(handler)
    return this
  }

  catch(handler) {
    this._catchObserver.push(handler)
    return this
  }

  finally(handler) {
    this._finallyObserver.push(handler)
    return this
  }

  cancel() {
    this.canceled.set(true)
  }

  isCanceled() {
    return this.canceled
  }

  static all(promises) {
    const results = new Array(promises.length)
    const length = promises.length
    let resolveCount = 0

    return new ResponsePromise((resolve, reject, progress, canceled) => {
      promises.forEach((promise, index) => {
        if (canceled.get() == true) throw Error()
        promise
          .then((result) => {
            results[index] = result
            results.proportion = ++resolveCount / length
            progress(results)
            if (resolveCount === length) resolve(results)
          })
          .catch(reject)
      })
    })
  }

  static sequence(inputs, handler) {
    const results = []
    const length = inputs.length
    let resolveCount = 0
    return new ResponsePromise((resolve, reject, progress, canceled) => {
      function invokeNext() {
        if (canceled.get() == true) throw new Error()
        handler
          .call(null, inputs[results.length])
          .then((result) => {
            results.push(result)
            results.proportion = ++resolveCount / length
            progress(results)
            if (results.length === length) resolve(results)
            else invokeNext()
          })
          .catch(reject)
      }
      invokeNext()
    })
  }
}
