import ResponsePromise from '../ResponsePromise'
import allmedia from '../../test/allImages.json'
//import latestMedia from '../../test/latestImages.json'
import StringUtils from '../../utils/StringUtils'

let friends = [
  {
    id: '232234242444',
    firstName: 'Mark',
    lastName: "O'Reilly"
  },
  {
    id: 'abcdse2323',
    firstName: 'Ron',
    lastName: 'Brady'
  },
  {
    id: 'abcdse232443',
    firstName: 'Alvaro',
    lastName: 'Vitali'
  },
  {
    id: 'abcdsdsdse2323',
    firstName: 'Tony',
    lastName: 'Dallara'
  },
  {
    id: 'abcdse34432323',
    firstName: 'Domenico',
    lastName: 'Rossi'
  },
  {
    id: 'abecdwee2323',
    firstName: 'Anna',
    lastName: 'Ley'
  },
  {
    id: 'abcdse23323',
    firstName: 'Ron',
    lastName: 'Brady'
  },
  {
    id: 'abdcdse2323',
    firstName: 'Ron',
    lastName: 'Brady'
  },
  {
    id: 'abcdse2323',
    firstName: 'Ron',
    lastName: 'Brady'
  },
  {
    id: 'abcdse2323',
    firstName: 'Ron',
    lastName: 'Brady'
  },
  {
    id: 'abcdse2323',
    firstName: 'Ron',
    lastName: 'Brady'
  },
  {
    id: 'abcdse2323',
    firstName: 'Ron',
    lastName: 'Brady'
  },
  {
    id: 'abcdse2323',
    firstName: 'Ron',
    lastName: 'Brady'
  },
  {
    id: 'abcdse23essd3',
    firstName: 'Last',
    lastName: 'One'
  }
]

let profile = {
  id: '123456789',
  username: 'testuser',
  enabled: true,
  firstName: 'Mario',
  lastName: 'Rossi',
  email: 'mario.rossi@gmail.com',
  registrationTimeStamp: 1596322760621,
  profileAvatar: null,
  usageQuotas: {
    'image/png': { count: 340, size: 233343 },
    'image/jpg': { count: 140, size: 2334343 },
    'image/raw': { count: 1040, size: 23335654343 }
  }
}

let mediaRequestResult = {
  meta: {
    total: allmedia.length
  },
  items: allmedia
}

let tags = [
  'one',
  'had',
  'by',
  'word',
  'but',
  'not',
  'what',
  'all',
  'were',
  'we',
  'when',
  'your',
  'can',
  'said',
  'there',
  'use',
  'an',
  'each',
  'which',
  'she',
  'do',
  'how',
  'their',
  'if',
  'will',
  'up',
  'other',
  'about',
  'out',
  'many',
  'then',
  'them',
  'these',
  'so',
  'some',
  'her',
  'would',
  'make',
  'like',
  'him',
  'into',
  'time',
  'has',
  'look',
  'two',
  'more',
  'write',
  'go',
  'see',
  'number',
  'no',
  'way',
  'could',
  'people',
  'my',
  'than',
  'first',
  'water',
  'been',
  'call',
  'who',
  'oil',
  'its',
  'now',
  'find',
  'long',
  'down',
  'day',
  'did',
  'get',
  'come',
  'made',
  'may',
  'part',
  'apple',
  'orange',
  'grape',
  'car',
  'holiday',
  'mountain',
  'sea',
  'wave',
  'sun',
  'rome',
  'dublin',
  'cat',
  'plant',
  'food',
  'friends',
  'family',
  'apple',
  'orange',
  'grape',
  'car',
  'holiday',
  'mountain',
  'sea',
  'wave',
  'sun',
  'rome',
  'dublin',
  'cat',
  'plant',
  'food',
  'friends',
  'family'
]

let stats = [
  {
    mimeType: 'MP4',
    size: 6176979777,
    count: 27
  },
  {
    mimeType: 'PNG',
    size: 34281737,
    count: 42
  },
  {
    mimeType: 'JPEG',
    size: 18699253627,
    count: 7221
  }
]

let statsByYear = {
  createdDate: [
    {
      year: 2021,
      count: 4
    }
  ],
  dateTaken: [
    {
      year: 2010,
      count: 1
    },
    {
      year: 2015,
      count: 1
    },
    {
      year: 2020,
      count: 2
    }
  ]
}

let shares = [
  {
    id: '60818ea15270bc4d39243e74',
    shortCode: '1YNAl',
    owner: '6a0438d6-c31d-49e9-8414-e32166275484',
    userName: 'Roberto Prato',
    apiKey: null,
    active: true,
    createdDate: '2021-04-22T14:56:33.424Z',
    dateModified: '2021-04-22T22:12:53.271Z',
    expiryDate: null,
    mediaIds: ['603b5694484e537e280e2821'],
    mediaType: ['video/mp4'],
    mediaLocations: [
      'XYZW3ByYXRvLXBob3Rvc106MjI3NmNiOTYtY2EzYi00MTBjLWI3ZDktYWNhNGMxN2VlNTcyLm1wNA'
    ],
    allowedCountries: null,
    blockedCountries: null,
    latestEvent: {
      id: '6081f4e50fe2817ccf4353db',
      createdDate: '2021-04-22T22:12:53.247Z',
      shareId: '60818ea15270bc4d39243e74',
      context: 'image/0',
      type: 'OPEN',
      userAgent:
        'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_5) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/90.0.4430.85 Safari/537.36',
      remoteIP: '0:0:0:0:0:0:0:1',
      owner: '6a0438d6-c31d-49e9-8414-e32166275484'
    },
    imageSize: 1000
  }
]

let timeout = 500
let failCounter = 0

for (const m in allmedia) {
  allmedia[m].id = 'mock-' + StringUtils.randomString(10)
}

export default {
  shouldFail() {
    failCounter++
    return failCounter % 2 == 0
  },

  getStats() {
    let promise = new Promise((resolve) => {
      let wait = setTimeout(() => {
        clearTimeout(wait)

        resolve(stats)
      }, timeout)
    })
    return promise
  },

  getStatsByYear() {
    let promise = new Promise((resolve) => {
      let wait = setTimeout(() => {
        clearTimeout(wait)

        resolve(statsByYear)
      }, timeout)
    })
    return promise
  },

  getImage({ location, w, h, type, cb }) {
    console.log('loading image: ' + w + h + type)
    setTimeout(() => {
      cb(location)
    }, 10)
  },

  getTags(cb) {
    let wait = setTimeout(() => {
      clearTimeout(wait)
      cb(tags)
    }, timeout)
  },

  getFriends() {
    let promise = new Promise((resolve) => {
      let wait = setTimeout(() => {
        clearTimeout(wait)

        resolve(friends)
      }, timeout)
    })
    return promise
  },

  getShares() {
    let promise = new Promise((resolve) => {
      let wait = setTimeout(() => {
        clearTimeout(wait)

        resolve(shares)
      }, timeout)
    })
    return promise
  },

  getCurrentUserAccount() {
    let promise = new ResponsePromise((resolve, reject, progress, canceled) => {
      let wait = setTimeout(() => {
        clearTimeout(wait)

        if (canceled.get()) {
          reject(false)
        } else {
          resolve(profile)
        }
      }, timeout)
    })
    return promise
  },

  getRecentMedia() {
    let promise = new ResponsePromise((resolve, reject, progress, canceled) => {
      let wait = setTimeout(() => {
        clearTimeout(wait)

        if (canceled.get()) {
          reject(false)
        } else {
          resolve(mediaRequestResult)
        }
      }, timeout)
    })
    return promise
  },

  getMedia() {
    let promise = new ResponsePromise((resolve, reject, progress, canceled) => {
      let wait = setTimeout(() => {
        clearTimeout(wait)

        if (canceled.get()) {
          reject(false)
        } else {
          resolve(mediaRequestResult)
        }
      }, timeout)
    })
    return promise
  },
  getAllMedia() {
    let promise = new ResponsePromise((resolve, reject, progress, canceled) => {
      let wait = setTimeout(() => {
        clearTimeout(wait)

        if (canceled.get()) {
          reject(false)
        } else {
          resolve(mediaRequestResult)
        }
      }, timeout)
    })
    return promise
  },

  deleteMedia(mediaId) {
    let promise = new Promise((resolve) => {
      setTimeout(() => {
        resolve(mediaId)
      }, timeout)
    })
    return promise
  },

  updateTags(media, tags) {
    let promise = new ResponsePromise((resolve, reject, progress, canceled) => {
      let wait = setTimeout(() => {
        clearTimeout(wait)

        if (this.shouldFail() || canceled.get()) {
          reject('error with api')
        } else {
          resolve(tags)
        }
      }, timeout)
    })
    return promise
  },

  addTags(media, tags) {
    let promise = new ResponsePromise((resolve, reject, progress, canceled) => {
      let wait = setTimeout(() => {
        clearTimeout(wait)

        if (this.shouldFail() || canceled.get()) {
          reject('error with api')
        } else {
          resolve(tags)
        }
      }, timeout)
    })
    return promise
  },
  createShortLink(media) {
    let promise = new Promise((resolve) => {
      setTimeout(() => {
        resolve({ id: media.length, shortCode: 'AcdFR5' })
      }, timeout)
    })
    return promise
  },
  removeTags(media, tags) {
    let promise = new ResponsePromise((resolve, reject, progress, canceled) => {
      let wait = setTimeout(() => {
        clearTimeout(wait)

        if (this.shouldFail() || canceled.get()) {
          reject('error with api')
        } else {
          resolve(tags)
        }
      }, timeout)
    })
    return promise
  },
  _uploadMedia(file) {
    return function (promise) {
      let prg = 0
      const interval = setInterval(() => {
        prg += 10
        promise.progress(prg)
        if (prg >= 100 || promise.isCanceled()) {
          clearInterval(interval)
          if (promise.isCanceled() == true) {
            console.log('canceled upload', file)
            promise.reject('canceled')
          } else {
            promise.resolve(file)
          }
        }
      }, 1000)
    }
  }
}
