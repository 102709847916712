const APP_VERSION = '1.438.0'

import isElectron from 'is-electron'

class Config {
  constructor() {
    this.mockapi = true
  }

  version() {
    return APP_VERSION
  }

  useMockapi() {
    if (process.env.NODE_ENV === 'production') {
      return false
    }
    return this.mockapi
  }

  isNativeApp() {
    return isElectron()
  }
  parallelUploads() {
    return 2
  }

  getMediaManagerURL() {
    return process.env.VUE_APP_MEDIA_MANAGER
  }
  getMediaShareURL() {
    return process.env.VUE_APP_MEDIA_SHARE
  }
  getMediaProcessingURL() {
    return process.env.VUE_APP_MEDIA_PROCESSING
  }

  getUserAccountrURL() {
    return process.env.VUE_APP_USER_ACCOUNT
  }

  getVideoStreamPlaylist(media) {
    return (
      process.env.VUE_APP_MEDIA_PROCESSING +
      '/stream/hls/' +
      encodeURIComponent(media.location) +
      '/playlist.m3u8'
    )
  }

  getAllowedMediaType() {
    return ['image', 'video' /**'audio'**/]
  }

  getAllowedMediaFormat() {
    return ['image/jpeg', 'image/jpg', 'image/png', 'video/mp4', 'video/mpeg']
  }

  getUploadChunkSize() {
    return parseInt(process.env.VUE_APP_UPLOAD_CHUNK_SIZE)
  }

  getShortLink(code) {
    return process.env.VUE_APP_SHARE_URL + '/' + code
  }

  getFeatures() {
    return {
      dashboard: {
        showRecent: false
      },
      search: {
        enabled: true,
        filters: false
      },
      media: {
        pageSize: 50,
        dateFormat: 'ddd, mmm dS yyyy, H:MM:ss',
        filter: {
          type: {
            labels: ['All', 'Images', 'Videos'],
            values: ['', 'image', 'video']
          }
        },
        sort: {
          byLabel: ['Date Added', 'Date Taken'],
          byKey: ['createdDate', 'dateTaken'],
          sortOder: ['desc', 'asc'],
          sortOderLabels: ['recent first', 'oldest first']
        }
      }
    }
  }
}
export default new Config()
