import BMF from 'browser-md5-file'

export class TBool {
  constructor() {
    this.value = false
    this.observers = []
  }

  set(value) {
    let changed = this.value != value
    this.value = value
    if (changed) {
      this._notify()
    }
    return changed
  }
  get() {
    return this.value
  }

  observe(cb) {
    this.observers.push(cb)
    cb(this.value)
  }

  _notify() {
    this.observers.forEach((cb) => {
      cb(this.value)
    })
  }
}

export class TArray {
  constructor(data = []) {
    this.data = []
    if (data instanceof Array) {
      this.add(data)
    }
  }

  add(array = []) {
    var _this = this
    array.forEach((item) => {
      _this.data.push(item)
    })
  }

  removeAtIndex(index) {
    if (index >= 0 && index < this.length) {
      this.data.splice(index, 1)
    }
  }

  get(index) {
    return this.data[index]
  }

  length() {
    return this.data.length
  }

  clear() {
    this.data.length = 0
  }

  empty() {
    return this.data.length == 0
  }

  pushUnique(value) {
    if (!this.contains(value)) {
      this.data.push(value)
      return true
    }

    return false
  }

  contains(object) {
    var index = this.data.indexOf(object)
    return index >= 0
  }

  remove(object) {
    var index = this.data.indexOf(object)
    this.removeAtIndex(index)
  }
}

export function getReadableFileSizeString(fileSizeInBytes) {
  var i = -1
  var byteUnits = [' kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB']
  do {
    fileSizeInBytes = fileSizeInBytes / 1024
    i++
  } while (fileSizeInBytes > 1024)

  return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i]
}

export function getRandomIntBetween(min, max) {
  return Math.floor(Math.random() * (max - min) + min)
}

export function base64ArrayBuffer(arrayBuffer) {
  var base64 = ''
  var encodings =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/'

  var bytes = new Uint8Array(arrayBuffer)
  var byteLength = bytes.byteLength
  var byteRemainder = byteLength % 3
  var mainLength = byteLength - byteRemainder

  var a, b, c, d
  var chunk

  // Main loop deals with bytes in chunks of 3
  for (var i = 0; i < mainLength; i = i + 3) {
    // Combine the three bytes into a single integer
    chunk = (bytes[i] << 16) | (bytes[i + 1] << 8) | bytes[i + 2]

    // Use bitmasks to extract 6-bit segments from the triplet
    a = (chunk & 16515072) >> 18 // 16515072 = (2^6 - 1) << 18
    b = (chunk & 258048) >> 12 // 258048   = (2^6 - 1) << 12
    c = (chunk & 4032) >> 6 // 4032     = (2^6 - 1) << 6
    d = chunk & 63 // 63       = 2^6 - 1

    // Convert the raw binary segments to the appropriate ASCII encoding
    base64 += encodings[a] + encodings[b] + encodings[c] + encodings[d]
  }

  // Deal with the remaining bytes and padding
  if (byteRemainder == 1) {
    chunk = bytes[mainLength]

    a = (chunk & 252) >> 2 // 252 = (2^6 - 1) << 2

    // Set the 4 least significant bits to zero
    b = (chunk & 3) << 4 // 3   = 2^2 - 1

    base64 += encodings[a] + encodings[b] + '=='
  } else if (byteRemainder == 2) {
    chunk = (bytes[mainLength] << 8) | bytes[mainLength + 1]

    a = (chunk & 64512) >> 10 // 64512 = (2^6 - 1) << 10
    b = (chunk & 1008) >> 4 // 1008  = (2^6 - 1) << 4

    // Set the 2 least significant bits to zero
    c = (chunk & 15) << 2 // 15    = 2^4 - 1

    base64 += encodings[a] + encodings[b] + encodings[c] + '='
  }

  return base64
}

export function convertFileToImageUrl(file, cb) {
  //var type = file.type.split('/')[1];
  var reader = new FileReader()
  reader.onload = function () {
    cb(reader.result)
  }

  reader.readAsDataURL(file)
}

export function convertFileToImage(file, cb) {
  var type = file.type.split('/')[1]
  var reader = new FileReader()
  reader.onload = function () {
    convertDataToImage(reader.result, type, cb)
  }

  reader.readAsArrayBuffer(file)
}
export function convertDataToImage(buffer, type, cb) {
  let base64 = base64ArrayBuffer(buffer)
  let d = `data:${type};base64,${base64}`
  let image = new Image()
  image.onload = function () {
    cb(image)
  }
  image.src = d
}

async function md5Impl(file) {
  const initPromise = new Promise((outerResolve, outerReject) => {
    const bmf = new BMF()
    bmf.md5(file, (err, m) => {
      if (err) {
        outerReject(err)
      } else {
        outerResolve(m)
      }
    })
  })
  return await initPromise
}

export function MD5(file) {
  return md5Impl(file)
}

//convert email to short username
export function displayableUserName(input) {
  if (undefined == input) {
    return ''
  }
  var index = input.indexOf('@')

  if (index > -1) {
    input = input.substr(0, index)
  }

  index = input.indexOf('.')
  if (index > -1) {
    input = input.substr(0, index)
  }

  return input
}

export function parseFolder(item, callback) {
  if (item.isFile) {
    // Get file
    item.file(function (file) {
      callback(file)
    })
  } else if (item.isDirectory) {
    var dirReader = item.createReader()
    dirReader.readEntries(function (entries) {
      for (var i = 0; i < entries.length; i++) {
        parseFolder(entries[i], callback)
      }
    })
  }
}

export function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}
