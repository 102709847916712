import CollectionUtils from '../utils/CollectionUtils'
import ResponsePromise from '../api/ResponsePromise'

class TaskWrapper {
  constructor(queue, task, resolve, reject, progress, canceled) {
    this.taskId = queue.nextTaskId()
    this.queue = queue
    this.task = task
    this._resolve = resolve
    this._progress = progress
    this._reject = reject
    this._canceled = canceled
    const _this = this
    this._canceled.observe((value) => {
      if (value == true) {
        _this.queue._onTaskCanceled(_this)
      }
    })
  }
  resolve(data) {
    console.log(' task ' + this.taskId + ' resolved')
    this._resolve(data)
    this.queue._onTaskCompleted(this)
  }

  reject(error, data) {
    let event = {
      error: error,
      data: data
    }
    console.log(' task ' + this.taskId + ' reason', event)
    this._reject(event)
    this.queue._onTaskCompleted(this)
  }
  progress(data) {
    this._progress.notify(data)
  }

  isCanceled() {
    return this._canceled.get()
  }

  onCanceled(cb) {
    this._canceled.observe(cb)
  }

  execute() {
    console.log(' task ' + this.taskId + ' executing')
    // try {
    this.task(this)
    //} catch (err) {
    //   this.reject(err)
    // }
  }

  _cancel() {
    console.log(' task ' + this.taskId + ' canceled')
    return this._canceled.set(true)
  }
}

export default class Queue {
  constructor(concurrentTask) {
    this.taskCounter = 0
    this.maxConcurrent = concurrentTask ? concurrentTask : 1
    this.pendingTasks = []
    this.runningTasks = []
    this.commands = []
    this.started = false
  }

  nextTaskId() {
    return this.taskCounter++
  }
  size() {
    return this.pendingTasks.length + this.runningTasks.length
  }

  start() {
    this.started = true
    this._next()
  }

  stop() {
    this.started = false
  }

  cancelAll() {
    console.log('cancel all tasks called')
    this.stop()
    let all = this.runningTasks.concat(this.pendingTasks)
    this.pendingTasks = []
    this.runningTasks = []
    all.forEach((task) => {
      task._cancel()
    })
    this.start()
    console.log('cancel all tasks ended')
  }

  push(task) {
    const _queue = this
    const promise = new ResponsePromise(
      (resolve, reject, progress, canceled) => {
        let taskWrapper = new TaskWrapper(
          _queue,
          task,
          resolve,
          reject,
          progress,
          canceled
        )

        _queue.pendingTasks.push(taskWrapper)
        _queue._next()
      }
    )

    return promise
  }

  _next() {
    while (
      this.started &&
      this.pendingTasks.length > 0 &&
      this.runningTasks.length < this.maxConcurrent
    ) {
      let task = this.pendingTasks.shift()
      if (task.isCanceled() == false) {
        this.runningTasks.push(task)
        setTimeout(() => {
          task.execute()
        }, 5)
      } else {
        console.log('task canceled before start ', task)
      }
    }
  }

  _onTaskCanceled(task) {
    this.pendingTasks = CollectionUtils.remove(this.pendingTasks, task)
    this.runningTasks = CollectionUtils.remove(this.runningTasks, task)
    this._next()
  }
  _onTaskCompleted(task) {
    this.runningTasks = CollectionUtils.remove(this.runningTasks, task)
    this._next()
  }
}
