class CollectionUtils {
  remove(array, object) {
    var index = array.indexOf(object)
    if (index >= 0) {
      array.splice(index, 1)
    }
    return array
  }

  has(array, object) {
    return array.includes(object)
  }

  pushUnique(array, object) {
    var index = array.indexOf(object)
    if (index < 0) {
      array.push(object)
      return true
    }
    return false
  }

  /**
   * Sets the values in the newValue array in the current array
   * instead of assigning the 2 arrays this preserves the reactivity
   * @param {Any} current
   * @param {Any} newValues
   */
  set(current, newValues) {
    current.length = 0
    newValues.forEach((i) => {
      current.push(i)
    })
  }

  serialize(array) {
    let result = ''

    array.forEach((value) => {
      if (result != '') {
        result += ','
      }
      result += value
    })

    return result
  }
}

export default new CollectionUtils()
