import Vue from 'vue'
import Vuex from 'vuex'
import { createLogger } from 'vuex'

import media from './modules/media'
import auth from './modules/auth'
import nav from './modules/nav'
import upload from './modules/upload'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    media: media,
    auth: auth,
    nav: nav,
    upload: upload
  },
  strict: debug,
  plugins: debug ? [createLogger()] : []
})
