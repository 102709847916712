<template>
  <div class="h-100 d-block">
    <div class="league-spartans-font main-container">
      <div class="content-container-box">
        <img class="logo" src="img/brand/logo-black-small.png" alt="Taggr" />
        <h2 class="mt-5 mb-5">Log in to your account</h2>
        <label for="input-live">Email Address:</label>
        <b-form-input
          id="input-live"
          v-model="name"
          :state="nameState"
          aria-describedby="input-live-help input-live-feedback"
          placeholder="Enter your email"
          trim
        ></b-form-input>
        <label class="mt-3" for="input-live">Password:</label>
        <b-form-input
          id="input-live"
          type="password"
          v-model="name"
          :state="nameState"
          aria-describedby="input-live-help input-live-feedback"
          placeholder="Enter your password"
          trim
        ></b-form-input>
        <p class="w-100">
          <b-button variant="link" class="float-right"
            >forgot password?</b-button
          >
        </p>
        <b-button variant="success" class="mt-5 w-100">Login</b-button>
      </div>
      <div class="content-container-image">
        <div class="content-attribuition justify-content-bottom">
          Photo by
          <a
            href="https://unsplash.com/@invictar1997?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText"
            >Soragrit Wongsa</a
          >
          on
          <a
            href="https://unsplash.com/s/photos/polaroid?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText"
            >Unsplash</a
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {}
</script>
<style scoped>
.main-container {
  display: flex;
  flex-direction: row;
  font-family: Arial, Helvetica, sans-serif;
  width: 100%;
  background: white;
}

.content-container-box {
  padding: 30px 30px 30px 30px;
  display: inline-block;

  width: 385px;
  height: 100vh;
  background: white;
  order: 2;
}

.content-container-image {
  left: 385px;
  display: block;
  width: calc(100vw - 385px);
  position: absolute;
  background-image: url('/img/brand/soragrit-wongsa-aV5xrpB0bwQ-unsplash.jpg');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  height: 100vh;
  order: 2;
}

.content-attribuition {
  padding-right: 10px;
  position: absolute;
  bottom: 0;
  right: 0;
}
.content-attribuition > a {
  color: black;
}
</style>
