const errors = {
  ERROR_REQUEST_CANCELED_BY_USER: 'ERROR_REQUEST_CANCELED_BY_USER',
  ERROR_REQUEST_MD5_ISSUE: 'ERROR_REQUEST_MD5_ISSUE',
  ERROR_REQUEST_MEDIA_ALREADY_UPLOADED: 'ERROR_REQUEST_MEDIA_ALREADY_UPLOADED',
  ERROR_REQUEST_NETWORK_ERROR: 'Network Error',
  ERROR_GENERIC_ERROR: 'ERROR_GENERIC_ERROR',
  REQUEST_API_OK: 'OK'
}

export { errors }
