import ResponsePromise from '../ResponsePromise'
import store from '../../store'
import axios from 'axios'
import config from '../../config'
import BMF from 'browser-md5-file'
import { errors } from '../const'
import { Upload } from 'tus-js-client'
import { uuidv4 } from '../../utils'

const imageCache = new Map()

export default {
  getTags(cb) {
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()

    axios
      .get(process.env.VUE_APP_MEDIA_MANAGER + '/media/tags', {
        cancelToken: source.token,
        headers: store.getters['auth/headers']
      })
      .then((response) => {
        let data = response.data
        cb(data)
      })
    return source
  },
  addTags(mediaId, tags) {
    // const CancelToken = axios.CancelToken
    // const source = CancelToken.source()
    return new Promise((resolve, reject) => {
      axios
        .put(
          process.env.VUE_APP_MEDIA_MANAGER + '/media/' + mediaId + '/tags/add',
          tags,
          {
            // cancelToken: source.token,
            headers: store.getters['auth/headers']
          }
        )
        .then((response) => {
          let data = response.data
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  removeTags(mediaId, tags) {
    // const CancelToken = axios.CancelToken
    // const source = CancelToken.source()
    return new Promise((resolve, reject) => {
      axios
        .put(
          process.env.VUE_APP_MEDIA_MANAGER +
            '/media/' +
            mediaId +
            '/tags/remove',
          tags,
          {
            // cancelToken: source.token,
            headers: store.getters['auth/headers']
          }
        )
        .then((response) => {
          let data = response.data
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateTags(mediaId, tags) {
    // const CancelToken = axios.CancelToken
    // const source = CancelToken.source()
    return new Promise((resolve, reject) => {
      axios
        .put(
          process.env.VUE_APP_MEDIA_MANAGER + '/media/' + mediaId + '/tags',
          tags,
          {
            // cancelToken: source.token,
            headers: store.getters['auth/headers']
          }
        )
        .then((response) => {
          let data = response.data
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  getImage({ location, w, h, type, cb }) {
    var src = location.replace('app:', '')
    const request = new URL(
      process.env.VUE_APP_MEDIA_PROCESSING + '/storage/location'
    )
    request.searchParams.append('media', src)
    if (w != undefined) {
      request.searchParams.append('w', w)
    }
    if (h != undefined) {
      request.searchParams.append('h', h)
    }
    if (type != undefined) {
      request.searchParams.append('t', type)
    }

    const requestKey = JSON.stringify(request)
    const requestKeyValue = imageCache.get(requestKey)

    if (requestKeyValue != undefined) {
      cb(requestKeyValue, undefined)
      return
    }

    const CancelToken = axios.CancelToken
    const source = CancelToken.source()

    axios
      .get(request, {
        cancelToken: source.token,
        responseType: 'blob',
        headers: store.getters['auth/headers']
      })
      .then((response) => {
        let url = URL.createObjectURL(response.data)
        imageCache.set(requestKey, url)
        cb(url, undefined)
      })
      .catch((error) => {
        cb(undefined, error)
      })

    return source
  },

  deleteMedia(mediaId) {
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()

    return new Promise((resolve, reject) => {
      axios
        .delete(config.getMediaManagerURL() + '/media/' + mediaId, {
          cancelToken: source.token,
          headers: store.getters['auth/headers']
        })
        .then(() => {
          resolve(mediaId)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  getRecentMedia() {
    let promise = new ResponsePromise((resolve, reject, progress, canceled) => {
      const CancelToken = axios.CancelToken
      const source = CancelToken.source()

      canceled.observe((value) => {
        if (value == true) {
          source.cancel()
        }
      })

      axios
        .get(config.getMediaManagerURL() + '/media', {
          cancelToken: source.token,
          headers: store.getters['auth/headers']
        })
        .then((response) => {
          let data = response.data
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })

    return promise
  },

  getAllMedia() {
    let promise = new ResponsePromise((resolve, reject, progress, canceled) => {
      const CancelToken = axios.CancelToken
      const source = CancelToken.source()

      canceled.observe((value) => {
        if (value == true) {
          source.cancel()
        }
      })

      axios
        .get(config.getMediaManagerURL() + '/media', {
          cancelToken: source.token,
          headers: store.getters['auth/headers']
        })
        .then((response) => {
          let data = response.data
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })

    return promise
  },
  getFriends() {
    let promise = new Promise((resolve, reject) => {
      const CancelToken = axios.CancelToken
      const source = CancelToken.source()

      axios
        .get(config.getUserAccountrURL() + '/account/me/friends', {
          cancelToken: source.token,
          headers: store.getters['auth/headers']
        })
        .then((response) => {
          let data = response.data
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })

    return promise
  },
  getShares() {
    let promise = new Promise((resolve, reject) => {
      const CancelToken = axios.CancelToken
      const source = CancelToken.source()

      axios
        .get(config.getMediaShareURL() + '/api', {
          cancelToken: source.token,
          headers: store.getters['auth/headers']
        })
        .then((response) => {
          let data = response.data
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })

    return promise
  },
  createShortLink(media) {
    let promise = new Promise((resolve, reject) => {
      const CancelToken = axios.CancelToken
      const source = CancelToken.source()

      axios
        .post(
          config.getMediaShareURL() + '/api/create',
          {
            ids: media
          },
          {
            cancelToken: source.token,
            headers: store.getters['auth/headers']
          }
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })

    return promise
  },
  getStats() {
    let promise = new Promise((resolve, reject) => {
      const CancelToken = axios.CancelToken
      const source = CancelToken.source()

      axios
        .get(config.getMediaManagerURL() + '/media/stats', {
          cancelToken: source.token,
          headers: store.getters['auth/headers']
        })
        .then((response) => {
          let data = response.data
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })

    return promise
  },
  getStatsByYear() {
    let promise = new Promise((resolve, reject) => {
      const CancelToken = axios.CancelToken
      const source = CancelToken.source()

      axios
        .get(config.getMediaManagerURL() + '/media/stats/years', {
          cancelToken: source.token,
          headers: store.getters['auth/headers']
        })
        .then((response) => {
          let data = response.data
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })

    return promise
  },
  getCurrentUserAccount() {
    let promise = new ResponsePromise((resolve, reject, progress, canceled) => {
      const CancelToken = axios.CancelToken
      const source = CancelToken.source()

      canceled.observe((value) => {
        if (value == true) {
          source.cancel()
        }
      })

      axios
        .get(config.getUserAccountrURL() + '/account/me', {
          cancelToken: source.token,
          headers: store.getters['auth/headers']
        })
        .then((response) => {
          let data = response.data
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })

    return promise
  },

  getMedia(request) {
    let promise = new Promise((resolve, reject) => {
      axios
        .get(config.getMediaManagerURL() + '/media', {
          params: request.toParams(),
          headers: store.getters['auth/headers']
        })
        .then((response) => {
          let data = response.data
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })

    return promise
  },

  _findByChecksum(file, promise) {
    console.log(file, promise)

    const CancelToken = axios.CancelToken
    const source = CancelToken.source()

    axios
      .get(config.getMediaManagerURL() + '/media/find?checksum=' + file.md5, {
        cancelToken: source.token,
        headers: store.getters['auth/headers']
      })
      .then((response) => {
        let data = response.data
        console.log('media with checksum found ', data)
        promise.reject(errors.ERROR_REQUEST_MEDIA_ALREADY_UPLOADED, data)
        // if (
        //   (file.md5 == data.checksum && data.status == 'UPLOADED') ||
        //   data.status == 'READY'
        // ) {
        //   promise.reject(errors.ERROR_REQUEST_MEDIA_ALREADY_UPLOADED)
        // } else {
        //   //start - restart upload
        // }

        // resolve(data)
      })
      .catch((error) => {
        if (error['response'] && error['response']['status'] == 404) {
          promise.resolve('OK')
        } else {
          promise.reject(errors.ERROR_REQUEST_NETWORK_ERROR, error)
        }
      })
  },

  /**
   *  calculare MD5 for the file
   * @param {TFile} file
   * @param {ProgressPromise} promise
   */
  _uploadStep1MD5(file, promise) {
    const _this = this
    const bmf = new BMF()
    const progressCallback = function (progress) {
      if (promise.isCanceled() == true) {
        bmf.abort()
      } else {
        promise.progress(progress * 50)
      }
    }

    const resultCallback = function (err, md5) {
      if (err) {
        if ('aborted' == err) {
          promise.reject(errors.ERROR_REQUEST_CANCELED_BY_USER)
        } else {
          promise.reject(errors.ERROR_REQUEST_MD5_ISSUE)
        }
      } else {
        file.setMD5(md5)
        _this._uploadStep2CheckIfAlreadyUploaded(file, promise)
        //promise.resolve(file)
      }
    }

    bmf.md5(file.file, resultCallback, progressCallback)
  },

  /**
   * Check if the file with the given MD5 is already uploaded
   * @param {TFile} file
   * @param {ProgressPromise} promise
   */
  _uploadStep2CheckIfAlreadyUploaded(file, promise) {
    const correlationId = uuidv4()
    const headers = store.getters['auth/headers']
    headers['x-correlation-id'] = correlationId
    const _this = this

    const CancelToken = axios.CancelToken
    const source = CancelToken.source()

    axios
      .get(config.getMediaManagerURL() + '/media/find?checksum=' + file.md5, {
        cancelToken: source.token,
        headers: headers
      })
      .then((response) => {
        let data = response.data
        console.log('media with checksum found ', data)
        promise.reject(errors.ERROR_REQUEST_MEDIA_ALREADY_UPLOADED, data)
      })
      .catch((error) => {
        if (error['response'] && error['response']['status'] == 404) {
          _this._uploadStep3Upload(file, promise, correlationId)
        } else {
          promise.reject(errors.ERROR_REQUEST_NETWORK_ERROR, error)
        }
      })
  },

  _uploadStep3Upload(file, promise, correlationId) {
    const md5 = file.getMD5()

    var upload = new Upload(file.file, {
      endpoint: process.env.VUE_APP_TUS_SERVER,
      retryDelays: [0, 3000, 5000, 10000, 20000],
      chunkSize: config.getUploadChunkSize(),
      fingerprint: function () {
        return Promise.resolve(md5)
      },
      onBeforeRequest: function (request) {
        request.setHeader('x-correlation-id', correlationId)
        const headers = store.getters['auth/headers']
        for (const [key, value] of Object.entries(headers)) {
          request.setHeader(key, value)
        }
      },
      metadata: {
        tags: Array.from(file.getTags()).join(),
        checksum: file.getMD5(),
        filename: file.name(),
        filetype: file.type()
      },
      onError: function (error) {
        promise.reject(errors.ERROR_REQUEST_NETWORK_ERROR, error)
        console.log('Failed because: ' + error)
      },
      onProgress: function (bytesUploaded, bytesTotal) {
        var p1 = (bytesUploaded / bytesTotal) * 50
        var percentage = 50 + p1
        promise.progress(percentage)
      },
      onSuccess: function () {
        promise.resolve(upload)
      }
    })

    upload.start()

    promise.onCanceled((value) => {
      if (value) {
        upload.abort(true)
      }
    })
  },

  _uploadMedia(file) {
    const _this = this
    return function (promise) {
      ///calculate MD5 firs
      _this._uploadStep1MD5(file, promise)
    }
  },

  shareMedia(mediaId, users) {
    // const CancelToken = axios.CancelToken
    // const source = CancelToken.source()
    return new Promise((resolve, reject) => {
      axios
        .post(
          process.env.VUE_APP_MEDIA_MANAGER + '/media/' + mediaId + '/share',
          users,
          {
            // cancelToken: source.token,
            headers: store.getters['auth/headers']
          }
        )
        .then((response) => {
          let data = response.data
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  prepareXMLHttpRequest(request) {
    let headers = store.getters['auth/headers']
    for (const [key, value] of Object.entries(headers)) {
      request.setRequestHeader(key, value)
    }
    request.setRequestHeader(
      'Access-Control-Allow-Headers',
      'Content-Type, Accept, X-Requested-With'
    )
    request.setRequestHeader(
      'Access-Control-Allow-Origin',
      'https://api.taggr-app.com'
    )
    request.setRequestHeader('Access-Control-Allow-Credentials', 'true')
  }
}
