import mock from './mock'
import impl from './impl'
import config from '../config'
import Queue from '../utils/Queue'
import CollectionUtils from '../utils/CollectionUtils'

const uploadQueue = new Queue(config.parallelUploads())
uploadQueue.start()

let implementation = config.useMockapi() ? mock : impl

function executeFunctionByName(functionName, context /*, args */) {
  var args = Array.prototype.slice.call(arguments, 2)
  var namespaces = functionName.split('.')
  var func = namespaces.pop()
  for (var i = 0; i < namespaces.length; i++) {
    context = context[namespaces[i]]
  }
  return context[func].apply(context, args)
}

export class MediaTypeFilter {
  constructor() {
    this.key = 'type'
    this.labels = ['all', 'images', 'videos']
    this.values = ['', 'image', 'video']
    this.selected = 0
  }

  toParams(map) {
    if (this.selected > 0) {
      map['type'] = this.values[this.selected]
    }
  }
}

export class MediaRequest {
  constructor(sortBy = 0, sortOder = 0) {
    this.sortByIndex = sortBy
    this.sortOderIndex = sortOder
    this.currentPage = 1
    this.pageSize = config.getFeatures().media.pageSize
    this.type = ''
    this.searchTerm = ''
    this.hasMore = true
    this.meta = []
    this.year = undefined
  }

  requestTotal(should) {
    if (should == true) {
      this.meta.push('total')
    } else {
      this.meta = []
    }
  }

  toParams() {
    let params = new Map()
    let mediaFeature = config.getFeatures().media
    if (this.currentPage == 0) {
      this.currentPage = 1
    }

    if (this.year != undefined) {
      //2000-10-31T01:30:00.000-05:00;
      params['afterDate'] = this.year + '-01-01T00:00:00.000-00:00'
      params['beforeDate'] = this.year + '-12-31T23:59:59.000-00:00'
    }

    if (this.meta.length > 0) {
      params['meta'] = CollectionUtils.serialize(this.meta)
    }

    params['page'] = this.currentPage
    params['pageSize'] = this.pageSize
    params['sortBy'] = mediaFeature.sort.byKey[this.sortByIndex]
    params['sortOrder'] = mediaFeature.sort.sortOder[this.sortOderIndex]
    if (this.searchTerm.length > 0) {
      params['searchTerm'] = this.searchTerm
    }
    if (this.type.length > 0) {
      params['type'] = this.type
    }

    return params
  }
}

export default {
  getImage({ location, w, h, type, cb }) {
    return executeFunctionByName('getImage', implementation, {
      location,
      w,
      h,
      type,
      cb
    })
  },

  getTags(cb) {
    return executeFunctionByName('getTags', implementation, cb)
  },

  getMedia(request) {
    return executeFunctionByName('getMedia', implementation, request)
  },

  getFriends() {
    return executeFunctionByName('getFriends', implementation)
  },
  getRecentMedia() {
    return executeFunctionByName('getRecentMedia', implementation)
  },

  getAllMedia() {
    return executeFunctionByName('getAllMedia', implementation)
  },

  deleteMedia(mediaId) {
    return executeFunctionByName('deleteMedia', implementation, mediaId)
  },

  pendingUploadsCount() {
    return uploadQueue.size()
  },

  cancelPendingUploads() {
    uploadQueue.cancelAll()
  },

  uploadMedia(file) {
    let uploader = executeFunctionByName('_uploadMedia', implementation, file)
    return uploadQueue.push(uploader)
  },

  updateTags(media, tags) {
    return executeFunctionByName('updateTags', implementation, media, tags)
  },

  addTags(media, tags) {
    return executeFunctionByName('addTags', implementation, media, tags)
  },

  removeTags(media, tags) {
    return executeFunctionByName('removeTags', implementation, media, tags)
  },

  getCurrentUserAccount() {
    return executeFunctionByName('getCurrentUserAccount', implementation)
  },

  shareMedia(media, users) {
    return executeFunctionByName('shareMedia', implementation, media, users)
  },

  createShortLink(ids) {
    return executeFunctionByName('createShortLink', implementation, ids)
  },

  getStats() {
    return executeFunctionByName('getStats', implementation)
  },
  getStatsByYear() {
    return executeFunctionByName('getStatsByYear', implementation)
  },

  getShares() {
    return executeFunctionByName('getShares', implementation)
  },

  prepareXMLHttpRequest(request) {
    return executeFunctionByName(
      'prepareXMLHttpRequest',
      implementation,
      request
    )
  }
}
