import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import config from './config'
Vue.prototype.$taggrConfig = config

import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
// Your variable overrides
// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'
import './scss/theme.scss'
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

//Logger
import VueLogger from 'vuejs-logger'

const isProduction = process.env.NODE_ENV === 'production'

Vue.use(VueLogger, {
  isEnabled: true,
  logLevel: isProduction ? 'error' : 'debug',
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: '|',
  showConsoleColors: true
})

Vue.config.productionTip = false

Vue.filter('pluralize', (word, amount) =>
  amount > 1 || amount === 0 ? `${word}s` : word
)

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')

Vue.$log.info('process -> env', process.env)
