<template>
  <div>
    <div
      class="position-relative overflow-hidden p-3 p-md-5 text-center bg-light landing-background"
    >
      <div class="col-md-5 p-lg-5 mx-auto my-5">
        <h1
          class="display-5 font-weight-normal league-spartans-font text-black"
        >
          taggr
        </h1>

        <p class="lead font-weight-bold">
          Upload, Organize and Share your images and videos.
        </p>

        <router-link to="/dashboard">
          <b-button variant="success" size="lg">Get Started</b-button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home',
  components: {}
}
</script>
<style scoped></style>
