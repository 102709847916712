<template>
  <footer class="container py-5">
    <div class="row">
      <div class="col-12 col-md">
        <img src="img/brand/small-logo.png" />
        <small class="d-block mb-3 text-muted">&copy;{{ year }}</small>
        <a
          target="_blank"
          href="https://aws.amazon.com/what-is-cloud-computing"
        >
          <img
            class="aws-logo"
            src="https://d0.awsstatic.com/logos/powered-by-aws.png"
            alt="Powered by AWS Cloud Computing"
          />
        </a>
      </div>
      <div class="col-6 col-md">
        <h5>Features</h5>
        <ul class="list-unstyled text-small">
          <li><a class="text-muted" href="#">Manage photos and videos</a></li>
          <li><a class="text-muted" href="#">Sync devices</a></li>
          <li><a class="text-muted" href="#">Share with confort</a></li>
          <li><a class="text-muted" href="#">Privacy settings</a></li>
        </ul>
      </div>
      <div class="col-6 col-md">
        <h5>For Businesses</h5>
        <ul class="list-unstyled text-small">
          <li><a class="text-muted" href="#">Photographers</a></li>
          <li><a class="text-muted" href="#">Education</a></li>
          <li><a class="text-muted" href="#">Government</a></li>
          <li><a class="text-muted" href="#">Gaming</a></li>
        </ul>
      </div>
      <div class="col-6 col-md">
        <h5>About</h5>
        <ul class="list-unstyled text-small">
          <li><a class="text-muted" href="#">Team</a></li>
          <li><a class="text-muted" href="#">Locations</a></li>
          <li><a class="text-muted" href="#">Privacy</a></li>
          <li><a class="text-muted" href="#">Terms</a></li>
        </ul>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  name: 'public-footer',
  data() {
    return {
      year: new Date().getFullYear()
    }
  }
}
</script>
<style></style>
