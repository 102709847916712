const state = () => ({
  progress: 0
})

// getters
const getters = {
  all: (state) => state.def.urlStorage.findAllUploads
}

const actions = {
  setProgress({ commit }, progress) {
    commit('m_progress', progress)
  }
}

const mutations = {
  m_progress: function (state, progress) {
    state.progress = progress
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
