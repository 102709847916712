<template>
  <b-navbar
    :class="{ change_color: scrollPosition > 150 }"
    sticky
    toggleable="lg"
    type="light"
  >
    <router-link to="/">
      <b-navbar-brand>
        <img class="logo" src="img/brand/logo-black-small.png" alt="Taggr" />
      </b-navbar-brand>
    </router-link>

    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      <!-- <b-navbar-nav>
        <b-nav-item href="#">Link</b-nav-item>
        <b-nav-item href="#" disabled>Disabled</b-nav-item>
      </b-navbar-nav> -->

      <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-auto">
        <router-link to="/dashboard">
          <b-button variant="success" size="lg" type="submit">Login</b-button>
        </router-link>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>
<script>
// @ is an alias to /src

export default {
  name: 'PNav',
  components: {},
  data() {
    return { scrollPosition: 0 }
  },
  methods: {
    updateScroll() {
      this.scrollPosition = window.scrollY
    }
  },
  mounted() {
    window.addEventListener('scroll', this.updateScroll)
  }
}
</script>
<style scoped>
.change_color {
  background-color: white;
}
</style>
