<template>
  <div id="app" class="d-flex flex-column">
    <router-view name="header"></router-view>
    <main class="flex-fill">
      <router-view />
    </main>
    <footer>
      <router-view name="footer"></router-view>
    </footer>
  </div>
</template>
<script>
export default {}
</script>
