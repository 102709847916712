import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../pages/Home.vue'
import PNav from '../components/PublicNavBar.vue'
import PFooter from '../components/PublicFooter.vue'
import Login from '../pages/Login.vue'
import config from '../config'

import store from '../store/'

Vue.use(VueRouter)
const isNativeApp = config.isNativeApp()

let routes = [
  {
    path: '/about',
    name: 'About',
    components: {
      header: PNav,
      default: () =>
        import(/* webpackChunkName: "about" */ '../pages/About.vue'),
      footer: PFooter
    }
  },
  {
    path: '/login',
    name: 'Login',
    components: {
      default: Login
    }
  },
  {
    path: isNativeApp ? '/' : '/dashboard',
    name: 'Dashboard',
    components: {
      header: () =>
        import(
          /* webpackChunkName: "about" */ '../components/DashboardNavBar.vue'
        ),
      default: () =>
        import(/* webpackChunkName: "about" */ '../pages/Dashboard.vue')
    },
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/profile',
    name: 'Profile',
    components: {
      header: () =>
        import(
          /* webpackChunkName: "about" */ '../components/DashboardNavBar.vue'
        ),
      default: () =>
        import(/* webpackChunkName: "about" */ '../pages/Profile.vue')
    },
    meta: {
      requiresAuth: true
    }
  }
]

if (!isNativeApp) {
  routes.push({
    path: '/',
    name: 'Home',
    components: {
      header: PNav,
      default: Home,
      footer: PFooter
    }
  })
}

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior: (to) => {
    if (to.hash) {
      return { selector: to.hash }
    } else {
      return { x: 0, y: 0 }
    }
  }
})

router.beforeEach((to, from, next) => {
  store.dispatch('auth/init').then(() => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
      if (store.state.auth.authenticated) {
        next()
      } else {
        ///not logged in
        //store the path for redirect after login
        next(false)
        localStorage.pathAfterLogin = to.name
        store.dispatch('auth/login', to)
      }
    } else {
      if (store.state.auth.authenticated && localStorage.pathAfterLogin) {
        console.log(
          ' --->>> authenticated , we should go to ' +
            localStorage.pathAfterLogin
        )
        next(localStorage.pathAfterLogin)
        localStorage.removeItem('pathAfterLogin')
      } else {
        next()
      }
      //  }
    }
  })
})

export default router
