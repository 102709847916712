// initial state
const state = () => ({
  path: ''
})

// getters
const getters = {
  //value was cached ?? see here https://forum.vuejs.org/t/vuex-getter-not-re-evaluating-return-cached-data/55697/5
  //pathAfterLogin: state => localStorage.pathAfterLogin
}

// actions
const actions = {
  setCurrentPath({ commit }, path) {
    commit('setCurrentPath', path)
  },
  setPathAfterLogin({ commit }, path) {
    commit('setPathAfterLogin', path)
  },
  pathAfterLogin() {
    return Promise.resolve(localStorage.pathAfterLogin)
  }
}

// mutations
const mutations = {
  setCurrentPath(state, path) {
    localStorage.currentPath = path
    state.path = path
  },
  setPathAfterLogin(state, path) {
    localStorage.pathAfterLogin = path
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
