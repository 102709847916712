import Keycloak from 'keycloak-js'
import Vue from 'vue'
import config from '../../config'
import api from '../../api'
//import uploadManager from "../../api/tus";

const keycloak = Keycloak({
  url: process.env.VUE_APP_AUTH_SERVER_URL,
  realm: process.env.VUE_APP_AUTH_REALM,
  clientId: process.env.VUE_APP_AUTH_CLIENT_ID,
  redirect_uri: process.env.VUE_APP_BASE_PATH
})

const StatusValues = {
  UNDEFINED: 'UNDEFINED',
  INITIALIZING: 'INITIALIZING',
  READY: 'READY'
}
Object.freeze(StatusValues)

// initial state
const state = () => ({
  initPromise: undefined,
  intervalId: '',
  status: StatusValues.UNDEFINED,
  ///////////
  user2: null,
  authenticated: false,
  userName: null,
  fullName: null,
  email: null,
  token: null,
  tokenParsed: null,
  logoutFn: null,
  loginFn: null,
  login: null,
  createLoginUrl: null,
  createLogoutUrl: null,
  createRegisterUrl: null,
  register: null,
  accountManagement: null,
  createAccountUrl: null,
  loadUserProfile: null,
  loadUserInfo: null,
  subject: null,
  idToken: null,
  idTokenParsed: null,
  realmAccess: null,
  resourceAccess: null,
  refreshToken: null,
  refreshTokenParsed: null,
  timeSkew: null,
  responseMode: null,
  responseType: null,
  hasRealmRole: null,
  hasResourceRole: null
})

// getters
const getters = {
  isLoggedIn: function () {
    return keycloak.authenticated
  },
  userName: (state) => state.fullName,
  email: (state) => state.email,
  headers: function (state) {
    return {
      Authorization: 'Bearer ' + state.token,
      'x-connect-api-key': process.env.VUE_APP_X_CONNECT_API_KEY,
      'x-connect-version': config.version()
    }
  }
}

// actions
const actions = {
  init(context) {
    Vue.$log.debug('Auth init called')

    const initPromise = new Promise((outerResolve /*, outerReject*/) => {
      if (context.state.status == StatusValues.UNDEFINED) {
        keycloak.onReady = function (authenticated) {
          Vue.$log.debug('authenticated', authenticated)
          context.commit('updateStatus', StatusValues.READY)
          context.commit('updateWatchVariables', keycloak)
          outerResolve(keycloak)
        }

        keycloak.onAuthSuccess = function () {
          context.commit('updateWatchVariables', keycloak)
          // Check token validity every 10 seconds (10 000 ms) and, if necessary, update the token.
          // Refresh token if it's valid for less then 60 seconds
          /*const updateTokenInterval =*/ setInterval(
            () =>
              keycloak.updateToken(60).catch(() => {
                keycloak.clearToken()
              }),
            10000
          )
        }

        keycloak.onAuthRefreshSuccess = function () {
          context.commit('updateWatchVariables', keycloak)
        }

        context.commit('updateStatus', StatusValues.INITIALIZING)
        keycloak.init({ responseMode: 'query', enableLogging: true })
      } else if (context.state.status == StatusValues.READY) {
        outerResolve(keycloak)
      }
    })

    return initPromise
  },
  login(context) {
    return context.dispatch('init').then(() => {
      Vue.$log.info('init called by login')
      keycloak.login({ redirectUri: process.env.VUE_APP_BASE_PATH })
    })
  },
  logout(context) {
    clearInterval(context.state.intervalId)
    keycloak
      .logout({ redirectUri: process.env.VUE_APP_BASE_PATH })
      .then((resp) => {
        context.commit('logout', resp)
      })
  },
  userInfo(context) {
    keycloak.loadUserProfile().then((resp) => {
      context.commit('userInfo', resp)
    })
  },
  userInfo2(context) {
    api.getCurrentUserAccount().then((resp) => {
      context.commit('userInfo2', resp)
    })
  },
  refreshToken(context) {
    if (keycloak.authenticated == false) return
    if (context.state.intervalId) {
      clearInterval(context.state.intervalId)
    }
    var timerId = setInterval(() => {
      keycloak
        .updateToken(70)
        .then((refreshed) => {
          console.debug('token refreshed ', refreshed)
        })
        .catch(() => {})
    }, 60000)

    context.commit('refreshToken', timerId)
  }
}

// mutations
const mutations = {
  initPromise(state, p) {
    state.initPromise = p
  },
  updateStatus(state, status) {
    state.status = status
  },
  userInfo(state, user) {
    state.status = 'success'
    state.user = user
  },
  userInfo2(state, user) {
    state.user2 = user
  },
  refreshToken(state, intervalId) {
    state.intervalId = intervalId
  },
  logout(state) {
    clearInterval(state.intervalId)
    state.intervalId = undefined
    state.status = ''
    state.token = ''
    state.user = {}
  },
  updateWatchVariables(state, keycloak) {
    state.authenticated = keycloak.authenticated
    state.loginFn = keycloak.login
    state.login = keycloak.login
    state.createLoginUrl = keycloak.createLoginUrl
    state.createLogoutUrl = keycloak.createLogoutUrl
    state.createRegisterUrl = keycloak.createRegisterUrl
    state.register = keycloak.register
    if (keycloak.authenticated) {
      state.accountManagement = keycloak.accountManagement
      state.createAccountUrl = keycloak.createAccountUrl
      state.hasRealmRole = keycloak.hasRealmRole
      state.hasResourceRole = keycloak.hasResourceRole
      state.loadUserProfile = keycloak.loadUserProfile
      state.loadUserInfo = keycloak.loadUserInfo
      state.token = keycloak.token
      state.subject = keycloak.subject
      state.idToken = keycloak.idToken
      state.idTokenParsed = keycloak.idTokenParsed
      state.realmAccess = keycloak.realmAccess
      state.resourceAccess = keycloak.resourceAccess
      state.refreshToken = keycloak.refreshToken
      state.refreshTokenParsed = keycloak.refreshTokenParsed
      state.timeSkew = keycloak.timeSkew
      state.responseMode = keycloak.responseMode
      state.responseType = keycloak.responseType
      state.tokenParsed = keycloak.tokenParsed
      state.userName = keycloak.tokenParsed['preferred_username']
      state.fullName = keycloak.tokenParsed['name']
      state.email = keycloak.tokenParsed['email']
    } else {
      state.accountManagement = undefined
      state.createAccountUrl = ''
      state.hasRealmRole = false
      state.hasResourceRole = false
      state.loadUserProfile = function () {}
      state.loadUserInfo = function () {}
      state.token = ''
      state.subject = ''
      state.idToken = ''
      state.idTokenParsed = ''
      state.realmAccess = ''
      state.resourceAccess = ''
      state.refreshToken = ''
      state.refreshTokenParsed = ''
      state.timeSkew = ''
      state.responseMode = ''
      state.responseType = ''
      state.tokenParsed = ''
      state.userName = ''
      state.fullName = ''
    }
    // uploadManager.updateHeaders({
    //   "Authorization" : "Bearer " + state.token,
    //   "x-connect-api-key": process.env.VUE_APP_X_CONNECT_API_KEY
    // });
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
