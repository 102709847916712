import api from '../../api'

// initial state
const state = () => ({
  searchTerm: undefined,
  all: [],
  tags: [],
  gallery: [],
  galleryIndex: 0,
  pendingRequest: undefined
})

// getters
const getters = {
  tags: (state) => {
    return state.tags
  }
}

// actions
const actions = {
  searchMedia(context, term) {
    context.commit('setSearchTerm', term)
  },

  getTags(context) {
    api.getTags((tags) => {
      context.commit('setTags', tags)
    })
  },
  getMedia(context, text) {
    if (context.state.pendingRequest != undefined) {
      context.state.pendingRequest.cancel('Operation canceled by the user.')
    }

    let promise

    if (text) {
      promise = api.getMediaByTags(text, (medias) => {
        context.commit('setMedia', medias)
      })
    } else {
      promise = api.getMedia((medias) => {
        context.commit('setMedia', medias)
      })
    }
    context.commit('setPendingRequest', promise)
  }
}

// mutations
const mutations = {
  setMedia(state, list) {
    state.all = list
  },
  setPendingRequest(state, promise) {
    state.pendingRequest = promise
  },
  setTags(state, tags) {
    state.tags = tags
  },
  setSearchTerm(state, term) {
    state.searchTerm = term
  },
  setMediaForGallery(state, { index, media }) {
    state.gallery = media
    state.galleryIndex = index
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
